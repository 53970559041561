import { Controller } from "stimulus";

export default class extends Controller {
  initialize() {

  }

  click() {
    this.element.querySelector("input[type=file]").click();
  }

  render(event) {
    let output = this.element.querySelector('#image-preview');
    output.src = URL.createObjectURL(event.target.files[0]);
    output.onload = function() {
      URL.revokeObjectURL(output.src) // free memory
    }
  }
}
