import { Controller } from "stimulus";

export default class extends Controller {
  initialize() {

  }

  click() {
    this.element.querySelector("input[type=file]").click();
  }

  render(event) {
    let output = this.element.querySelector('#file-preview');
    output.innerHTML = event.target.files[0].name;
  }
}
